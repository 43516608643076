<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'create_invoices_man' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Add Invoice</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="invoices"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="FileTextIcon"
              class="cursor-pointer"
              size="16"
              @click=" $router.push({
                name: 'show-paking-list',
                params: { id: props.row.id} ,
              })"
            />
            <b-tooltip
              title="Packing list"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click=" $router.push({
                name: 'show-invoice',
                params: { id: props.row.id} ,
              })"
            />
            <b-tooltip
              title="Show"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click=" $router.push({
                name: 'edit-invoice',
                params: { id: props.row.id} ,
              })"
            />
            <b-tooltip
              title="edit"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Number',
          field: 'reference',
          filterOptions: {
            enabled: true,
            placeholder: 'search number',
          },
        },
        {
          label: 'Customer',
          field: 'customer.code',
          filterOptions: {
            enabled: true,
            placeholder: 'search customer',
          },
        },

        {
          label: 'Date',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'search date',
          },
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      invoices: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

  },
  created() {
    this.getInvoices()
  },
  methods: {
    // get client
    async getInvoices() {
      this.load = 'true'
      const { data } = await axiosIns.get('/invoices/')
      this.invoices = data
      this.load = 'false'
    },
    // delete client
    async deleteFacture(devid) {
      const result = await this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      this.load = 'true'
      if (result.value) {
        try {
          await axiosIns.delete(`/invoices/delete/${devid}/`)
          this.load = 'false'
          this.getInvoices()
          this.showToast('success', 'top-center', 'facture supprimée avec succés')
        } catch (error) {
          this.load = 'false'
          this.showToast('danger', 'top-center', error.toString())
        }
      } else if (result.dismiss === 'cancel') {
        this.load = 'false'
        this.$swal({
          title: 'Fermer',
          text: 'Invoice Not deleted',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
